import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { COUNTRY_LIST, REGION_LIST } from '@/constants/national-list.const';
import type { CommonCountry } from '@/types/common/common.type';
import type { FormOption, FormOptionGroup } from '@/types/common/form.type';

export const useCountryStore = defineStore('countryStore', () => {
  const { locale } = useI18n();

  const sortedCountryList = computed<CommonCountry[]>(() => {
    return [...COUNTRY_LIST].sort((a: CommonCountry, b: CommonCountry) => {
      return locale.value === 'ko'
        ? a.krName.localeCompare(b.krName)
        : a.name.localeCompare(b.name);
    });
  });

  const countryOptions = computed<FormOptionGroup[]>(() => {
    return sortedCountryList.value.map((country: CommonCountry) => {
      const { code, phoneCd } = country;
      return {
        label: `studio.national_code.${code.toLowerCase()}`,
        value: code.toUpperCase(),
        extendedLabel: phoneCd
      };
    });
  });

  const sortedRegionList = computed<string[]>(() => {
    return Object.values(REGION_LIST).sort((a: string, b: string) => {
      return a.localeCompare(b);
    });
  });

  const regionOptions = computed<FormOption[]>(() => {
    return sortedRegionList.value.map((region: string) => {
      return {
        label: `studio.prj_prod.this_prod.edit_display_countries_regions_${region.toLowerCase()}`,
        value: region
      };
    });
  });

  const getCountryTranslationKeyByCountryCode = (countryCode?: string): string => {
    if (!countryCode) {
      return '';
    }

    const option = countryOptions.value.find(
      (option: FormOptionGroup) => option.value === countryCode
    );
    if (option) {
      return option.label;
    }

    return '';
  };

  return {
    countryOptions,
    getCountryTranslationKeyByCountryCode,
    sortedCountryList,
    regionOptions
  };
});
